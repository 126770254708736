import { preloadImages, preloadFonts } from './utils';
import LocomotiveScroll from 'locomotive-scroll';
import CircleType from 'circletype';

const backtopEl = document.querySelector('.backtop');
const headerEl = document.querySelector('#header');

const mainTitle = document.querySelector('#mainTitle');

console.log("updating title curve...");
var c = new CircleType(mainTitle).radius(684);

// slideshowTitle.forEach(element => {
//     new CircleType(element).radius(684);
// });

// Preload  images and fonts
Promise.all([preloadImages('.tiles__line-img'), preloadFonts('rmd7deq')]).then(() => {
    // Remove loader (loading class)
    document.body.classList.remove('loading');

    // Initialize the Locomotive scroll
    const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        smoothMobile: true
    });

    backtopEl.addEventListener('click', () => scroll.scrollTo(headerEl));
});

